import { Calendar } from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction'
import rrulePlugin from '@fullcalendar/rrule'
import dayGridPlugin from '@fullcalendar/daygrid'

import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'

// import GLightbox from 'glightbox'
// import Tobii from '@midzer/tobii'
import { Fancybox } from "@fancyapps/ui";

import Swiper, { Navigation } from 'swiper';

import Swup from 'swup';
import SwupMorphPlugin from 'swup-morph-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';
import SwupHtmlLangPlugin from '@mashvp/swup-html-lang-plugin';
import SwupA11yPlugin from '@swup/a11y-plugin';

let tobii;

const swup = new Swup({
  containers: [
    '#main', 
    'header',
  ],
  plugins: [
    new SwupMorphPlugin({
      containers: [
        '.aside-content',
        'footer',
        '.breadcrumb',
      ]
    }),
    new SwupScrollPlugin(),
    new SwupHtmlLangPlugin(),
    new SwupA11yPlugin(),
  ]
});

function initSwiper() {
  const swiper = new Swiper('.swiper', 
    {
      modules: [Navigation],
      loop: true,
      slidesPerView: 'auto',
      lazyPreloadPrevNext: 2,
      spaceBetween: 0,
      centeredSlides: false,
      navigation: {
        nextEl: '.swiper-button-next-g',
        prevEl: '.swiper-button-prev-g',
      },
    }
  );
}

function initCalendar() {
  const calendarEl = document.getElementById('calendar');
  const lang = document.documentElement.getAttribute('lang');
  if (calendarEl) {
      const calendar = new Calendar(calendarEl, {
        plugins: [
          interactionPlugin,
          dayGridPlugin,
          rrulePlugin
        ],
        initialView: 'dayGridMonth',
        editable: true,
        events: {
          url: lang == 'en' ? '/en/events.json' : '/events.json',
          startParam: 'calendar_start',
          endParam: 'calendar_end'
        },
        eventContent: function(arg) {
          // render date # in event
          return arg.event.start.getDate().toString();
        },
        dayHeaderContent: function(arg) {
          // m t w t f s s for english, mo di mi do fr sa so for german
          return lang == 'en' ? arg.text.split('')[0] : arg.text;
        },
        eventClick: function(info) {
          swup.loadPage({url: info.event.url});
        },
        firstDay: 1, // monday
        locale: lang,
        headerToolbar: {
          start: 'prev', // will normally be on the left. if RTL, will be on the right
          center: 'title',
          end: 'next' // will normally be on the right. if RTL, will be on the left
        },
        titleFormat: {
          year: 'numeric', month: 'long',
        },
        height: 'auto',
      })
  
      calendar.render()
  }
}

function initLightbox() {
  //! fancybox:
  console.log(Fancybox);
  Fancybox.bind("[data-fancybox]", {
    Thumbs: false,
    Toolbar: {
      display: {
        left: ["infobar"],
        middle: [],
        right: ["close"],
      },
    }
  });

  //! glightbox:
  // const lightbox = GLightbox({
  //   loop: true,
  //   touchNavigation: true,
  //   openEffect: 'fade',
  //   closeEffect: 'fade',
  //   moreLength: 0,
  // });

  //! tobii:
  // let scrollPosition = 0;
  // tobii = new Tobii({
  //    zoom: false,
  //    captionHTML: true,
  //    captionsSelector: 'self',
  //    captionAttribute: 'data-caption',
  //    captions: true,
  //    counter: false,
  // });

  // tobii.on('open', () => {
  //   const scrollY = window.scrollY;
  //   const main = document.querySelector('main');
  //   main.style.width = `${main.clientWidth + 1}px`;
  //   main.style.height = `${main.clientHeight + document.querySelector('footer').clientHeight}px`;
  //   main.style.top = `-${scrollY - document.querySelector('header').clientHeight}px`;
  //   main.style.position = 'fixed';
  // });
  // tobii.on('close', () => {
  //   const main = document.querySelector('main');
  //   const scrollY = main.style.top;
  //   main.style.position = '';
  //   main.style.width = '';
  //   main.style.height = '';
  //   main.style.top = '';
  //   window.scrollTo(0, parseInt(scrollY || '0') * -1);
  // });
}

// function destroyTobii() {
//   tobii.destroy();
// }

const quickFixMenuItemsWidth = function () {
  const menuItems = document.querySelectorAll('.main-menu li.has-submenu-items');
  menuItems.forEach(menuItem => {
    menuItem.style.width = menuItem.getBoundingClientRect().width + 'px';
  });
}

window.Alpine = Alpine
window.swup = swup
Alpine.plugin(focus);
Alpine.start();

// swup.on('enabled', e => {
//   console.log('swuppy');
// })

// swup.on('willReplaceContent', () => {
//   destroyTobii();
// })

swup.on('contentReplaced', e => {
  pageView();
})

function pageView() {
  quickFixMenuItemsWidth();
  initCalendar();
  initLightbox();
  initSwiper();
}

pageView();
